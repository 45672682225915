<template>
  <v-card
    flat
    style="background-color: #f4f6f9"
    class="pt-1"
  >
    <v-card-title>Edit Zone</v-card-title>
    <v-card-text class="black--text">
      <v-container fluid px-0 pt-1>
        <v-row no-gutters>
          <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12" class="pr-1">
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Zone Name</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  readonly
                  background-color="grey lighten-3"
                  v-model="editZone.name"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Short Name</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  readonly
                  background-color="grey lighten-3"
                  v-model="editZone.short_name"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Location</span>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
                <gmap-map
                  ref="googlemap"
                  :center="{
                    lat: Number(editZone.lat),
                    lng: Number(editZone.lng),
                  }"
                  :zoom="17"
                  style="width: 100%; height: 400px"
                  @click="onMapClick"
                >
                  <gmap-marker
                    :key="editZone.name"
                    :animation="2"
                    :position="{
                      lat: Number(editZone.lat),
                      lng: Number(editZone.lng),
                    }"
                  ></gmap-marker>
                </gmap-map>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Time Zone</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  readonly
                  background-color="grey lighten-3"
                  v-model="editZone.timezone"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Latitude</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  v-model="editZone.lat"
                  hide-details
                  readonly
                  background-color="grey lighten-3"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Longitude</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  v-model="editZone.lng"
                  hide-details
                  readonly
                  background-color="grey lighten-3"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="2"
                sm="2"
                xs="2"
                md="2"
                lg="2"
                xl="2"
                class="text-right pr-2 align-self-center"
              >
                <span>Capacity</span>
              </v-col>
              <v-col cols="10" class="text-start">
                <v-text-field
                  solo
                  flat
                  v-model="editZone.level_max"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Image</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-img
                v-if="editZone.image"
                :src="editZone.image"
                max-height="250"
                cover
                max-width="250"
              ></v-img>
              <span v-else class="pl-2">NA</span>
            </v-col>
          </v-row> -->
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="cancelEditZone">Cancel</v-btn>
      <v-btn
        color="primary"
        class="elevation-0"
        :loading="updateZoneLoading"
        @click="updateZone()"
        >Update Zone</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import * as API from "@/api/api";
export default {
  name: "Zone",
  components: {},
  data() {
    return {
      editZone: {},
      addOrEdit: "",
    };
  },
  computed: {
    updateZoneLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "UPDATE_ZONE"
      );
    },
  },
  watch: {},
  beforeCreate() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      var self = vm;
      //check whether the action is add or edit
      if (self.$router.currentRoute.name == "editzone") {
        self.editZone = Object.assign({}, self.editZone, self.$store.getters["pinaStore/getterZoneById"](
          self.$router.currentRoute.params.zid
        ));
        self.addOrEdit = "Edit";
      }
      // else if (self.$router.currentRoute.name == "addzone") {
      //   self.addOrEdit = "Add";
      //   self.zone = { id: null };
      // }
    });
  },
  mounted() {
    var self = this;
      let zone = self.$store.getters["pinaStore/getterZoneById"](
        self.$router.currentRoute.params.zid
      );
      self.editZone = !(zone == null || zone == undefined)
        ? Object.assign({}, zone)
        : {};
  },
  methods: {
    onMapClick(event) {
      var self = this;
      self.editZone.lat = event.latLng.lat();
      self.editZone.lng = event.latLng.lng();
    },
    cancelEditZone() {
      this.$router.push({ path: "/zone/zone" });
    },
    async updateZone() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "UPDATE_ZONE",
          pending: true,
        });
        let editZoneResult = await API.editZone(this.editZone);
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "UPDATE_ZONE",
          pending: false,
        });
        this.$notify({
          group: "divrt",
          title:
            editZoneResult?.data?.status && editZoneResult.data.status
              ? "Success"
              : "Error",
          text: editZoneResult?.data?.message
            ? editZoneResult.data.message
            : "",
          type:
            editZoneResult?.data?.status && editZoneResult.data.status
              ? "success"
              : "error",
        });
        EventBus.$emit("edited-zone");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
